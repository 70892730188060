<template>
    <div class="app">
        <div class="appmain">
            <el-row>
                <el-col :span="12" :xs="24">
                    <div class="leftinfo">
                        <div class="infotitle">{{$t('login.zhineng')}}</div>
                        <div class="infocontent">{{$t('login.dizao')}}<br/>{{$t('login.zhinengxin')}}<br/>{{$t('login.fendou')}}</div>
                        <div class="image">
                            <el-image :src="require('@/assets/index/bianzu'+($i18n.locale == 'zh' ? '' : '_'+$i18n.locale)+'.png')"></el-image>
                        </div>
                        
                    </div>
                </el-col>
                <el-col :span="9" :xs="24">
                    <div class="rightinfo">
                        <div class="denglu">{{$t('login.welcome')}}</div>
                        <div style="width:80%;margin:0 auto;margin-top:8%;">
                            <el-form ref="form" :rules="rules" :model="form" >
                                <el-form-item prop="username">
                                    <el-input v-model="form.username" :placeholder="$t('login.userName')"></el-input>
                                </el-form-item>
                                <el-form-item prop="password" v-if="!yanzhengma">
                                    <el-input type="password" v-model="form.password" :placeholder="$t('login.password')" @keyup.enter.native="loginbtn"></el-input>
                                </el-form-item>
                                <el-form-item prop="code" v-if="yanzhengma">
                                     <el-input v-model="form.code" :placeholder="$t('login.code')" @keyup.enter.native="loginbtn">
                                        <template slot="append">
                                            <span style="cursor:pointer" v-if="textopen" @click="getcode">{{text}}</span>
                                            <span v-if="!textopen">{{daojishi}}s</span>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <div class="loginbtn" @click="loginbtn">{{$t('login.logIn')}}</div>
                            <div class="loginbottom">
                                    <div style="cursor: pointer;" @click="nav('reg')">{{$t('login.register')}}</div>
                                    <div style="cursor: pointer;" @click="nav('forget')">{{$t('login.forgetPassword')}}</div>
                                    <div style="cursor: pointer;" @click="yanzhengmaview">{{yztext}}</div>
                                    <div style="cursor: pointer;"><el-checkbox v-model="checked"></el-checkbox>{{$t('login.rememberPassword')}}</div>
                            </div>
                            <div style="margin-top:8%;color: rgba(51, 51, 51, 0.5);font-size: 14px;font-family: SourceHanSansCN-Regular;font-weight: 400;">
                                {{$t('login.tishi')}}
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        
    </div>
</template>
<script>
import { login,getverify,getCode,codeLogin } from "@/api/api"; 
export default {
    data(){
        return{
            title:'',
            textopen:true,
            text:this.$t('getCode'),
            yztext: this.$t('login.codelogin'),
            daojishi:59,
            form:{
                key:'',
                username:'',
                password:''
            },
            rules:{
                username: [
                    { required: true, message: this.$t('login.usermsg'), trigger: "blur" }
                ],
                password: [
                    { required: true, message: this.$t('login.pwdmsg'), trigger: "blur" }
                ],
                code: [
                    { required: true, message: this.$t('login.codemsg'), trigger: "blur" }
                ],
            },
            content:'',
            yanzhengma:false,
            checked:false
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.login')+'-'+this.$t('seo.title')
        let username = this.$cookie.get('smartgenusername')
        let password = this.$cookie.get('smartgenpassword')
        if(username == undefined){
            this.checked = true
        }else{
            this.checked = true
            this.form.username = username 
            this.form.password = password
        }
        console.log(this.form);
        this.getCode()
    },
    methods:{
        async getCode(){
            const{data:res} = await getverify()
            this.form.key = res.key
            this.content = res.content
        },
        nav(path){
            this.$router.push('/'+path)
        },
        loginbtn(){
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if(this.yanzhengma){
                        codeLogin(this.form).then(res=>{
                            sessionStorage.setItem('userinfo',JSON.stringify(res.data))
                            this.$router.push('/index')
                        })
                    }else{
                        login(this.form).then(res=>{
                            if(this.checked){
                                this.$cookie.set('smartgenusername', this.form.username,{ expires: 7});
                                this.$cookie.set('smartgenpassword', this.form.password,{ expires: 7});
                            }else{
                                this.$cookie.remove('smartgenusername');
                                this.$cookie.remove('smartgenpassword');
                            }
                            sessionStorage.setItem('userinfo',JSON.stringify(res.data))
                            this.$router.push('/index')
                        })
                    }
                }
            });
        },
        yanzhengmaview(){
            this.yanzhengma = !this.yanzhengma
            if(this.yanzhengma){
                this.yztext = this.$t('login.userlogin')
            }else{
                this.yztext = this.$t('login.codelogin')
            }
        },
        async getcode(){
            let value = this.form.username
            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            const iphoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
            if(mailReg.test(value) || iphoneReg.test(value)){
                this.textopen = false
                let data = {
                    username:this.form.username
                }
                this.timer = setInterval(()=>{
                    this.daojishi--
                    if(this.daojishi===0){
                        this.daojishi = 60
                        this.textopen = true
                        clearInterval(this.timer)
                    }
                },1000)
                const {data:res} = await getCode(data)
            }else{
                this.$message.error(this.$t('reg.emtail'));
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 480px) {
        .app{
            .appmain{
                width: 100%;
                .leftinfo{
                    margin-top:0px ;
                    margin-left: 0px;
                }
            }
            .rightinfo{
                margin-top: 10px;
                margin-left: 0px;
                border-radius: 8px;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .app{
            .appmain{
                width: 100% !important;
            }
        }
        .leftinfo{
            margin-top: 20% !important;
        }
        .rightinfo{
            margin-top: 20% !important;
        }
    }
    .app{
        width: 100%;
        background: url('~@/assets/index/bg.png') no-repeat;
        height: 100vh;
    }
    .leftinfo{
        margin-top: 40%;
        margin-left: 40%;
        .infotitle{
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            font-family: SourceHanSansCN-Medium;
        }
        .infocontent{
            margin-top: 5%;
            font-size: 16px;
            line-height: 25px;
            color: #333333;
            font-family: SourceHanSansCN-Regular;
        }
        .image{
            margin-top: 5%;
        }
    }
    .rightinfo{
        border-radius: 8px;
        margin-top: 40%;
        margin-left: 25%;
        height: 400px;
        background: #FFFFFF;
        .denglu{
            font-size: 22px;
            text-align: center;
            padding-top: 12%;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
        }
        .loginbtn{
            width: 100%;
            margin: 0 auto;
            height: 48px;
            text-align: center;
            line-height: 48px;
            background: #0052D9;
            color: #FFFFFF;
            cursor: pointer;
        }
        .loginbottom{
            margin-top: 3%;
            color: #333333;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-family: SourceHanSansCN-Regular;
        }
    }
</style>